@use 'sass:map';
@use '@angular/material' as mat;
@use '../colors';

$-form-field-overrides: (
  outline-color: map.get(colors.$neutral-variant-palette, 90),
  hover-outline-color: map.get(colors.$secondary-palette, 40),
);

@mixin form-field-overrides($theme) {
  @include mat.form-field-color($theme, $color-variant: secondary);
  @include mat.form-field-overrides($-form-field-overrides);

  .mdc-text-field {
    background-color: var(--form-field-container-color);

    &--disabled {
      background-color: colors.$tns-dark-gray-50;
    }
  }

  .mat-mdc-form-field-icon-suffix {
    padding: 0 10px;
  }
}
