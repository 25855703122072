@use '../typography';
@use '../fonts';

@mixin set-icons-font {
  @include fonts.material-symbols;

  .material-symbols-outlined {
    font-size: typography.$font-size-base;

    &.filled {
      font-variation-settings: 'FILL' 1;
    }
  }
}
