@use '@angular/material' as mat;

@mixin progress-spinner-overrides($theme) {
  @include mat.progress-spinner-color($theme, $color-variant: secondary);

  mat-progress-spinner[agTheme] {
    &.primary {
      @include mat.progress-spinner-color($theme, $color-variant: primary);
    }
  }
}
