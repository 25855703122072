@use 'sass:map';
@use 'theme';

:root {
  --mat-app-background-color: #f5f6f8; // $tns-base-light-500
  --form-field-container-color: #fff; // $tns-white-500
}

html {
  @include theme.set-theme(theme.$light-theme);
}

body {
  background-color: var(--mat-app-background-color);
}
