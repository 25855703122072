@use 'sass:map';
@use '@angular/material' as mat;
@use '../colors';
@use '../typography';
@use '../utils';

@mixin box-shadow($color) {
  box-shadow: inset 0 0 0 0.2em $color;
}

$-button-common-overrides: (
  container-shape: 5px,
  horizontal-padding: 20px,
  container-height: 54px,
  label-text-size: 1em,
  label-text-weight: typography.$font-weight-bold,
);
$-stroked-common-overrides: (
  outline-width: 2px,
);

@mixin button-overrides($theme) {
  button[agTheme] {
    @include mat.button-color($theme, $color-variant: secondary);
    @include mat.button-overrides($-button-common-overrides);

    &:active {
      .mdc-button {
        &__label {
          color: map.get(colors.$secondary-palette, 100);
        }
      }
    }

    &[mat-stroked-button] {
      @include mat.button-overrides($-stroked-common-overrides);
    }

    &.primary {
      @include mat.button-color($theme, $color-variant: secondary);

      &[mat-stroked-button] {
        $-stroked-primary-overrides: (
          outline-color: map.get(colors.$secondary-palette, 40),
          ripple-color: map.get(colors.$secondary-palette, 40),
        );

        @include mat.button-overrides($-stroked-primary-overrides);
      }

      &[mat-flat-button]:active {
        @include box-shadow(map.get(colors.$secondary-palette, 10));
      }
    }

    &.success {
      $-success-overrides: (
        container-color: map.get(colors.$success-palette, 50),
      );

      @include mat.button-overrides($-success-overrides);

      &[mat-stroked-button] {
        $-stroked-success-overrides: (
          outline-color: map.get(colors.$success-palette, 10),
          label-text-color: map.get(colors.$success-palette, 50),
          ripple-color: map.get(colors.$success-palette, 10),
        );

        @include mat.button-overrides($-stroked-success-overrides);

        &:hover {
          background-color: rgba(map.get(colors.$success-palette, 50), 0.05);
        }
      }

      &[mat-flat-button]:active {
        @include box-shadow(map.get(colors.$success-palette, 10));
      }
    }

    &.error {
      @include mat.button-color($theme, $color-variant: error);

      $-error-overrides: (
        container-color: map.get(colors.$error-palette, 60),
      );

      @include mat.button-overrides($-error-overrides);

      &[mat-stroked-button] {
        $-stroked-error-overrides: (
          outline-color: map.get(colors.$error-palette, 10),
          ripple-color: map.get(colors.$error-palette, 10),
        );

        @include mat.button-overrides($-stroked-error-overrides);
      }

      &[mat-flat-button]:active {
        @include box-shadow(map.get(colors.$error-palette, 10));
      }
    }

    &.icon-only {
      width: fit-content;
      padding: 0;

      ui-icon {
        font-size: 24px;
      }
    }

    &.small {
      @include mat.button-overrides(
        (
          container-height: 34px,
          label-text-size: 0.875em,
        )
      );
    }
  }
}
