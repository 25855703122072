@use 'sass:map';
@use '@angular/material' as mat;
@use '../colors';
@use '../utils';

$-dialog-overrides: (
  container-shape: 5px,
  subhead-color: map.get(colors.$primary-palette, 40),
  subhead-size: 26px,
  headline-padding: 20px 30px 0,
  content-padding: 20px 30px,
  with-actions-content-padding: 20px 30px,
  actions-padding: 20px 30px,
);

@mixin dialog-overrides($theme) {
  @include mat.dialog-overrides($-dialog-overrides);

  mat-dialog-container {
    @include utils.styled-scrollbar;
  }

  [mat-dialog-title]::before {
    display: none;
  }

  mat-dialog-actions.mat-mdc-dialog-actions {
    display: grid;
    grid-auto-flow: column;
    gap: 20px;
    justify-content: initial;

    --mat-dialog-actions-alignment: none;

    @include utils.media-breakpoint-down(md) {
      grid-auto-flow: row;
    }

    button[agTheme].mat-mdc-button-base {
      margin: 0;
    }
  }

  mat-dialog-content.mat-mdc-dialog-content {
    max-height: 100%;
  }
}
