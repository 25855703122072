@use 'sass:map';
@use '@angular/material' as mat;
@use '../colors';
@use '../typography';

$-chips-overrides: (
  container-shape-radius: 20px,
  container-height: 40px,
  elevated-container-color: map.get(colors.$neutral-palette, 99),
  elevated-selected-container-color: map.get(colors.$primary-palette, 99),
  label-text-color: map.get(colors.$primary-palette, 40),
  selected-label-text-color: map.get(colors.$primary-palette, 70),
  outline-width: 0,
  flat-selected-outline-width: 1px,
  label-text-weight: typography.$font-weight-bold,
  with-avatar-avatar-size: 0,
);

@mixin chips-overrides($theme) {
  @include mat.chips-color($theme, $color-variant: primary);
  @include mat.chips-overrides($-chips-overrides);

  mat-chip-set,
  mat-chip-grid,
  mat-chip-option {
    &[agTheme] {
      &.error {
        $-error-overrides: (
          elevated-selected-container-color: map.get(colors.$error-palette, 99),
          selected-label-text-color: map.get(colors.$error-palette, 70),
          outline-color: map.get(colors.$error-palette, 10),
          focus-outline-color: map.get(colors.$error-palette, 10),
        );

        @include mat.chips-overrides($-error-overrides);
      }

      &.success {
        $-success-overrides: (
          elevated-selected-container-color:
            map.get(colors.$success-palette, 99),
          selected-label-text-color: map.get(colors.$success-palette, 30),
          outline-color: map.get(colors.$success-palette, 10),
          focus-outline-color: map.get(colors.$success-palette, 10),
        );

        @include mat.chips-overrides($-success-overrides);
      }

      &.warning {
        $-warning-overrides: (
          elevated-selected-container-color:
            map.get(colors.$warning-palette, 99),
          selected-label-text-color: map.get(colors.$warning-palette, 30),
          outline-color: map.get(colors.$warning-palette, 10),
          focus-outline-color: map.get(colors.$warning-palette, 10),
        );

        @include mat.chips-overrides($-warning-overrides);
      }
    }
  }
}
