@use 'sass:map';
@use '@angular/material' as mat;
@use '../colors';

$-button-toggle-overrides: (
  shape: 5px,
  height: 54px,
  divider-color: map.get(colors.$neutral-variant-palette, 70),
  selected-state-background-color: map.get(colors.$secondary-palette, 40),
  selected-state-text-color: map.get(colors.$secondary-palette, 100),
);

@mixin button-toggle-overrides($theme) {
  @include mat.button-toggle-overrides($-button-toggle-overrides);

  mat-button-toggle-group,
  mat-button-toggle {
    &[agTheme] {
      &.success {
        $-success-overrides: (
          selected-state-background-color: map.get(colors.$success-palette, 50),
          selected-state-text-color: map.get(colors.$success-palette, 100),
        );

        @include mat.button-toggle-overrides($-success-overrides);
      }

      &.error {
        $-error-overrides: (
          selected-state-background-color: map.get(colors.$error-palette, 60),
          selected-state-text-color: map.get(colors.$error-palette, 100),
        );

        @include mat.button-toggle-overrides($-error-overrides);
      }

      &.warning {
        $-warning-overrides: (
          selected-state-background-color: map.get(colors.$warning-palette, 60),
          selected-state-text-color: map.get(colors.$warning-palette, 100),
        );

        @include mat.button-toggle-overrides($-warning-overrides);
      }
    }
  }
}
