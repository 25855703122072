@use 'sass:map';
@use '@angular/material' as mat;
@use '../colors';

$-tabs-overrides: (
  active-indicator-height: 3px,
  active-label-text-color: map.get(colors.$secondary-palette, 40),
  active-focus-label-text-color: map.get(colors.$secondary-palette, 40),
  active-hover-label-text-color: map.get(colors.$secondary-palette, 40),
  inactive-focus-label-text-color: map.get(colors.$secondary-palette, 40),
);

@mixin tabs-overrides($theme) {
  @include mat.tabs-color($theme, $color-variant: secondary);
  @include mat.tabs-overrides($-tabs-overrides);

  .mdc-tab {
    &--active {
      --mat-tab-header-label-text-weight: 700;
    }
  }
}
