@use 'sass:map';
@use '@angular/material' as mat;
@use '../colors';

@mixin color($theme) {
  color: mat.get-theme-color($theme, primary, 80);
}

@mixin typography($theme) {
  font: mat.get-theme-typography($theme, label-large, font);
}

@mixin link-theme($theme, $color: colors.$tns-blue-500) {
  .text-link {
    @if $theme != '' {
      @if mat.theme-has($theme, color) {
        @include color($theme);
      }

      @if mat.theme-has($theme, typography) {
        @include typography($theme);
      }
    } @else {
      color: $color;
    }

    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: mat.get-theme-color($theme, primary, 60);
    }

    &.disabled {
      cursor: default;
      color: mat.get-theme-color($theme, neutral-variant, 70);
    }

    &.underline {
      text-decoration: underline;
    }
  }
}
