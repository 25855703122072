// TNS White
$tns-white-500: #fff;

// TNS Black
$tns-black-500: #000;

// TNS Dark Blue
$tns-dark-blue-700: #071c28;
$tns-dark-blue-600: #052536;
$tns-dark-blue-500: #002f49;
$tns-dark-blue-400: #095077;
$tns-dark-blue-300: #1a71a1;
$tns-dark-blue-200: #489bc9;
$tns-dark-blue-100: #a8d1e8;
$tns-dark-blue-50: #d0eaf8;

// TNS Blue
$tns-blue-700: #185479;
$tns-blue-600: #106598;
$tns-blue-500: #0076be;
$tns-blue-400: #258ccb;
$tns-blue-300: #53ade4;
$tns-blue-200: #8fcef8;
$tns-blue-100: #c2edff;
$tns-blue-50: #e0f6ff;

// TNS Light Blue
$tns-light-blue-700: #0e7ea8;
$tns-light-blue-600: #0b9cd2;
$tns-light-blue-500: #00aeef;
$tns-light-blue-400: #4aceff;
$tns-light-blue-300: #7edcff;
$tns-light-blue-200: #a9e8ff;
$tns-light-blue-100: #d8f4ff;
$tns-light-blue-50: #effbff;

// TNS Pink
$tns-pink-700: #9f1752;
$tns-pink-600: #af2b64;
$tns-pink-500: #b93b71;
$tns-pink-400: #d05188;
$tns-pink-300: #ea72a6;
$tns-pink-200: #ffb1d3;
$tns-pink-100: #ffeaf3;
$tns-pink-50: #fff7fa;

// TNS Warm Gray
$tns-warm-gray-700: #625044;
$tns-warm-gray-600: #75645a;
$tns-warm-gray-500: #86786f;
$tns-warm-gray-400: #a29287;
$tns-warm-gray-300: #c2b6ad;
$tns-warm-gray-200: #ddd5cf;
$tns-warm-gray-100: #f6f5f3;
$tns-warm-gray-50: #f8f8f8;

// TNS Dark Gray
$tns-dark-gray-700: #383431;
$tns-dark-gray-600: #4b4643;
$tns-dark-gray-500: #655c55;
$tns-dark-gray-400: #7b746d;
$tns-dark-gray-300: #a1a198;
$tns-dark-gray-200: #ddd;
$tns-dark-gray-100: #ededed;
$tns-dark-gray-50: #f6f6f6;

// TNS Green
$tns-green-700: #062d30;
$tns-green-600: #033c40;
$tns-green-500: #004f54;
$tns-green-400: #107b82;
$tns-green-300: #239ca4;
$tns-green-200: #3ec0c8;
$tns-green-100: #80e8ef;
$tns-green-50: #bbf3f6;

// TNS Mint Green
$tns-mint-green-700: #39a57c;
$tns-mint-green-600: #29b982;
$tns-mint-green-500: #12d188;
$tns-mint-green-400: #3fdda0;
$tns-mint-green-300: #6beab9;
$tns-mint-green-200: #9df2d2;
$tns-mint-green-100: #c7f6e4;
$tns-mint-green-50: #e4fbf2;

// TNS Purple
$tns-purple-700: #460f57;
$tns-purple-600: #5c1b70;
$tns-purple-500: #762e8c;
$tns-purple-400: #7b408d;
$tns-purple-300: #9b65ab;
$tns-purple-200: #bb8fce;
$tns-purple-100: #e0c6ec;
$tns-purple-50: #efe2f5;

// TNS Warning
$tns-warning-700: #9a1919;
$tns-warning-600: #b00e0e;
$tns-warning-500: #c00;
$tns-warning-400: #db303d;
$tns-warning-300: #ea4d59;
$tns-warning-200: #ef6d76;
$tns-warning-100: #f4959b;
$tns-warning-50: #fbc4c8;

// TNS Success
$tns-success-700: #0a5727;
$tns-success-600: #076c2e;
$tns-success-500: #008934;
$tns-success-400: #2aa458;
$tns-success-300: #3ec270;
$tns-success-200: #6fd796;
$tns-success-100: #b2ebc7;
$tns-success-50: #d8f2e2;

// TNS In Progress
$tns-in-progress-700: #cd9d26;
$tns-in-progress-600: #e2a919;
$tns-in-progress-500: #f6b000;
$tns-in-progress-400: #fec433;
$tns-in-progress-300: #ffd66f;
$tns-in-progress-200: #ffe9b2;
$tns-in-progress-100: #fef2d6;
$tns-in-progress-50: #fff9ea;

// TNS Base
$tns-base-light-500: #f5f6f8;
$tns-base-dark-500: #1f1f1f;

// TNS Icons
$tns-icons-500: #c2cfe0;
