$font-family: proxima-nova, helvetica, arial, sans-serif;
$font-size-base: 16px;

// font-weights for proxima nova and material symbols fonts
$font-weight-thin: 100;
$font-weight-normal: 400;
$font-weight-bold: 700;

html {
  font-family: $font-family;
  font-size: $font-size-base;
  letter-spacing: normal;

  body {
    font-family: inherit;
  }
}
